import {
  all, call, put, spawn, take,
} from 'redux-saga/effects';

import {
  handleAsynchronousCommand,
  handleHTTPCommand,
  handleSynchronousCommand,
} from 'dmpconnectjsapp-base/sagas/connectorSagas';
import { handleDMPSequence } from 'dmpconnectjsapp-base/sagas/initializationSagas';
import { handleRESTSequence } from 'dmpconnectjsapp-base/sagas/rest/securitySagas';
import { handleMonitoring } from 'dmpconnectjsapp-base/sagas/monitoringSagas';
import { createError, errorTypes, softwareErrors } from 'dmpconnectjsapp-base/errors';
import { setModalError } from 'dmpconnectjsapp-base/actions';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleCpxInfoExport } from './securitySagas';

import { handleCPXMonitoringUpdate } from './monitoringSagas';
import { saveInPersistantDataQueue } from './persistantDataSaga';
import { handleCallbacks, handleLocationChange } from '.';
import { storages } from '../../reducers';
import { handleDmpconnectErrors } from './connectorSagas';
import { createErrorDetails, createModalError } from '../errors';

export default function* rootSaga() {
  if (storages && storages.length > 0) {
    let rehydrated = false;
    const rehydratedStorages = [];
    while (rehydrated === false) {
      const { key } = yield take(REHYDRATE); // Wait for rehydrate to prevent sagas from running with empty store
      rehydratedStorages.push(key);
      if (storages.every(s => rehydratedStorages.includes(s))) {
        rehydrated = true;
      }
    }
  }

  const sagas = [
    { saga: handleCallbacks },
    { saga: handleSynchronousCommand },
    { saga: handleAsynchronousCommand },
    { saga: handleHTTPCommand },
    { saga: handleDmpconnectErrors },
    { saga: handleDMPSequence },
    { saga: handleRESTSequence },
    { saga: handleLocationChange },
    { saga: handleMonitoring, arg: 'cpx' },
    { saga: handleMonitoring, arg: 'vitale' },
    { saga: handleCpxInfoExport },
    { saga: handleCPXMonitoringUpdate },
    { saga: saveInPersistantDataQueue },
  ];

  yield all(sagas.map(({ saga, arg }) => spawn(function* () {
    while (true) {
      try {
        yield call(saga, arg);
        break;
      } catch (e) {
        console.log('unexpected error', e);
        const errorModal = createError(
          errorTypes.SoftwareErrors,
          softwareErrors.UNEXPECTED_ERROR,
        );

        const details = [createErrorDetails('Error', {
          name: e.name,
          message: e.message,
          toString: e.toString(),
          stack: e.stack || '',
        })];
        yield put(setModalError(createModalError(errorModal, details)));
      }
    }
  })));
}
