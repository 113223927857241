import merge from 'lodash.merge';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import {
  dmpconnectActionConstants,
  dmpconnectConfigurationActionConstants,
  dmpconnectUserActionConstants,
} from '../constants';
import env from '../../envVariables';

const initialStateFromEnv = {
  insiIdam: env.REACT_APP_INSI_ID_AM,
  insiNumAm: env.REACT_APP_INSI_NUM_AM,
  insiLpsName: env.REACT_APP_INSI_LPS_NAME,
  insiLpsVersion: env.REACT_APP_INSI_LPS_VERSION,

  insiWS4Min: Number(env.REACT_APP_WS4_MIN),
  insiWS4Max: Number(env.REACT_APP_WS4_MAX),

  batchImportUrl: env.REACT_APP_BATCH_IMPORT_URL,
  batchExportUrl: env.REACT_APP_BATCH_EXPORT_URL,
  cpxInfoExportUrl: env.REACT_APP_CPX_INFO_EXPORT_URL,
  batchAutoProcess: Number(env.REACT_APP_WS4_AUTO_PROCESS) === 1,

  resultExportUrl: env.REACT_APP_EXPORT_URL,
  autoExportToServer: Number(env.REACT_APP_AUTO_EXPORT_TO_SERVER) === 1,
  noExportToIframeParent: Number(env.REACT_APP_DO_NOT_EXPORT_TO_IFRAME_PARENT) === 1,

  forceLoginCheck: Number(env.REACT_APP_ES_LOGIN_CHECK) === 1,
  mockApiLogin: Number(env.REACT_APP_API_LOGIN_MOCK) === 1,
};

const initialState = {
  vitaleCardReader: -1,
  cpxCardReader: -1,
  logincheck: {},
  apiLoginValues: {
    psInternalId: null,
    patientInternalId: null,
  },
  [apiSections.PERSISTANT_DATA_LS]: {},
  ...initialStateFromEnv,
};

export function dmpConnectPersistedAppConfiguration(state = initialState, action) {
  switch (action.type) {
    case dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_CONFIGURATION:
      return merge({}, state, { [action.key]: action.value });
    case dmpconnectConfigurationActionConstants.DMPC_RESET_PERSIST_APP_CONFIGURATION_FROM_ENV:
      return merge({}, state, initialStateFromEnv);
    case dmpconnectUserActionConstants.DMPC_ES_LOGIN_SUCCESS:
    case dmpconnectUserActionConstants.DMPC_LOGIN_SUCCESS:
      return merge({}, state, { logincheck: null, apiLoginValues: initialState.apiLoginValues });
    case dmpconnectActionConstants.DMPC_SET_PERSISTANT_DATA_TO_LOCALSTORAGE:
      return merge({}, state, { [apiSections.PERSISTANT_DATA_LS]: action.command.data.s_persistantData64 });
    default:
      return state;
  }
}
